import { Sheet } from "xlsx-js-style";

import { definitions } from "@/api/types";
import { postGallery } from "@/app/[lng]/Polygon/components/UploadModal";

import axiosInstance from "../api/axiosInstance";

interface Daily extends ResourceList {
  author: string;
  construction: string;
  created_at: string;
  date: string;
  id: string;
  next_work_detail: string;
  title: string;
  updated_at: string;
  weather: string;
  work_detail: string;
}

interface ResourceList {
  resource: ResourceItem[];
}

interface ResourceItem {
  construction_resource: number;
  resource_category: "man" | "equipment" | "material";
  resource_name: string;
  resource_unit: string;
  subtotal: null | number;
  usage: null | number;
}

// export const constr_id = typeof window !== "undefined" ? localStorage.getItem("constr_id") : "";
export const AuthApi = {
  postLogin: (formData: FormData) => axiosInstance.post("/users/login/", formData),
  postLogout: (formData: FormData) => axiosInstance.post("/users/logout/", formData),
  postRefresh: (refresh: string) => axiosInstance.post("/users/refresh/", { refresh }),
  postChangePassword: (formData: FormData) =>
    axiosInstance.post("/users/change-password/", formData),
  getMypageDetail: (user_id: string, constr_id: string) =>
    axiosInstance.get(`/users/${user_id}/constructions/${constr_id}/`),
  putMypageDetail: (user_id: string, constr_id: string, formData: FormData) =>
    axiosInstance.put(`/users/${user_id}/constructions/${constr_id}/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
};

export const ConstructionsApi = {
  getConstructions: () => axiosInstance.get("/memberships/constructions"),
  getConstruction: (constr_id: string | null) =>
    axiosInstance.get(`/constructions/${constr_id}/`),
};

export const DashBoardApi = {
  getScheduleTable: (constr_id: string | null) =>
    axiosInstance.get(`/constructions/${constr_id}/schedules/`),
  patchSchedules: (constr_id: string | null, data: any) =>
    axiosInstance.patch(`/constructions/${constr_id}/schedules/`, data),
  postSchedules: (constr_id: string | null, data: Sheet[]) =>
    axiosInstance.post(`/constructions/${constr_id}/schedules/`, {
      data: JSON.stringify(data),
    }),
};

export const siteLogApi = {
  getDailyLogData: (
    constr_id: string | null,
    page: number,
    title: string,
    startDate: any,
    endDate: any
  ) =>
    axiosInstance.get(
      `/constructions/${constr_id}/dailysites/?page=${page}&title=${title}&sdate=${startDate}&edate=${endDate}`
    ),
  // getWorkLogData: (constr_id: string | null) =>
  //   axiosInstance.get(`/constructions/${constr_id}/dailysites/latest/`),
  getWorkLogData: (constr_id: string | null, date: any) =>
    axiosInstance.get(`/constructions/${constr_id}/dailysites/latest/?date=${date}`),
  postDaily: (constr_id: string | null, data: definitions["DailySiteDetail"]) =>
    axiosInstance.post(`/constructions/${constr_id}/dailysites/`, data),
  getDailyDetail: (constr_id: string | null, id: string, lng: string) =>
    axiosInstance.get<Daily[]>(
      `/constructions/${constr_id}/dailysites/${id}/?lang=${lng}`
    ),
  postDailyDetail: (constr_id: string | null, id: string, data: any) =>
    axiosInstance.post(`/constructions/${constr_id}/dailysites/${id}/`, data),
  getWeather: (constr_id: string | null, lng: string, date: string) =>
    axiosInstance.get(`/constructions/${constr_id}/weathers/?lang=${lng}&date=${date}`),
  getWorkLogMaterial: () => axiosInstance.get("/material/"),
  getMaterial: async (
    constr_id: string | null,
    search: string,
    category: string,
    page = 1
  ) => {
    const response = await axiosInstance.get(
      `/constructions/${constr_id}/resources/searches/`,
      {
        params: { stext: search, category, page },
      }
    );
    return response.data;
  },
  postMaterialAmount: (constr_id: string | null, materialData: definitions["Resource"]) =>
    axiosInstance.post(`/constructions/${constr_id}/resources/searches/`, materialData),
  getPeriodTodayData: (constr_id: string, startDate: any, endDate: any) =>
    axiosInstance.get(
      `/constructions/${constr_id}/period-reports/dailysite/?sdate=${startDate}&edate=${endDate}`
    ),
  getPeriodResourceData: (constr_id: string, startDate: any, endDate: any) =>
    axiosInstance.get(
      `/constructions/${constr_id}/period-reports/resource/?sdate=${startDate}&edate=${endDate}`
    ),
  getPeriodTitle: (constr_id: string) =>
    axiosInstance.get(`/constructions/${constr_id}/title`),
};

export const ReportsApi = {
  getReports: (
    constr_id: string | null,
    page: number,
    category: string | null,
    search: string,
    sdate: Date | string,
    edate: Date | string
  ) =>
    axiosInstance.get(
      `/constructions/${constr_id}/reports/?page=${page}&category=${
        category === "all" ? "" : category
      }&title=${search ? search : ""}&sdate=${sdate ? sdate : ""}&edate=${edate ? edate : ""}`
    ),
  deleteReport: (constr_id: string | null, report_id: string) =>
    axiosInstance.delete(`/constructions/${constr_id}/reports/${report_id}/`),

  getReportDetail: (constr_id: string | null, report_id: string) =>
    axiosInstance.get(`/constructions/${constr_id}/reports/${report_id}/`),

  postUploadFile: (formData: FormData, constr_id: string | null) =>
    axiosInstance.post(`/constructions/${constr_id}/reports/`, formData),

  UpdateUploadFile: (formData: FormData, constr_id: string | null, report_id: string) =>
    axiosInstance.post(`/constructions/${constr_id}/reports/${report_id}/`, formData),
};

export const GalleryApi = {
  postPhoto: (formData: FormData, constr_id: string | null) =>
    axiosInstance.post(`/constructions/${constr_id}/galleries/`, formData),
  getPhoto: (
    page: number,
    constr_id: string | null,
    startDate?: Date | null | string,
    endDate?: Date | null | string
  ) => {
    let url = `/constructions/${constr_id}/galleries/?`;
    if (page) url += `page=${page}&`;
    if (startDate) url += `sdate=${startDate}&`;
    if (endDate) url += `edate=${endDate}&`;

    return axiosInstance.get(url);
  },
  deletePhotos: (data: { image_id: string[] }, constr_id: string | null) =>
    axiosInstance.delete(`/constructions/${constr_id}/galleries/async/`, {
      data,
    }),
  deletePhoto: (imageId: string, constr_id: string | null) =>
    axiosInstance.delete(`/constructions/${constr_id}/galleries/${imageId}`),
};

export const DetectApi = {
  getDetects: (page: number, constr_id: string | null) =>
    axiosInstance.get(`/constructions/${constr_id}/detect/?page=${page}`),
  postDetect: (form: any, constr_id: string | null) =>
    axiosInstance.post(`/constructions/${constr_id}/detect/`, form),
  getDetectDetail: (id: string, constr_id: string | null) =>
    axiosInstance.get(`/constructions/${constr_id}/detect/${id}/`),
  getDetectProgress: (id: string, constr_id: string | null) =>
    axiosInstance.get(`/constructions/${constr_id}/detect/${id}/progress/`),
};

export const MaterialApi = {
  getMaterialList: (constr_id: any, page?: number, date?: string) => {
    let url = `${process.env.NEXT_PUBLIC_SERVICE_URL}/constructions/${constr_id}/resources/?`;
    if (page) url += `page=${page}&`;
    if (date) url += `date=${date}&`;

    return axiosInstance.get(url);
  },
};

export const PolygonApi = {
  getPhoto: (constr_id: string) =>
    axiosInstance.get(`constructions/${constr_id}/drawings/floorplans/`),
  getPolygonData: (constr_id: string, floor_id: string) =>
    axiosInstance.get(`constructions/${constr_id}/drawings/floorplans/${floor_id}/`),
  getAFloorPhotoInAllPolygons: (
    constr_id: string,
    floor_id: string,
    page?: number,
    process?: string
  ) => {
    let url = `constructions/${constr_id}/drawings/floorplans/${floor_id}/galleries/?`;
    if (page) url += `page=${page}&`;
    if (process) url += `process=${process}&`;
    return axiosInstance.get(url);
  },
  getAllFloorPhotoInAllPolygons: (constr_id: string, page?: number, process?: string) => {
    let url = `constructions/${constr_id}/drawings/floorplans/all/galleries/?`;
    if (page) url += `page=${page}&`;
    if (process) url += `process=${process}&`;
    return axiosInstance.get(url);
  },
  // getGallery: () => axiosInstance.get(`constructions/${constr_id}/drawings/process-filter/`),
  getPolygonPhoto: (
    constr_id: string,
    polygon_id: string,
    page?: number,
    process?: string
  ) => {
    let url = `constructions/${constr_id}/drawings/polygons/${polygon_id}/galleries/?`;
    if (page) url += `page=${page}&`;
    if (process) url += `process=${process}&`;
    return axiosInstance.get(url);
  },
  getPolygonGallery: (constr_id: string) =>
    axiosInstance.get(`constructions/${constr_id}/drawings/galleries/`),
  postPolygonPhoto: (constr_id: string, polygon_id: string, gallery_id: string) => {
    axiosInstance.post(
      `constructions/${constr_id}/drawings/polygons/${polygon_id}/galleries/${gallery_id}/`
    );
  },
  postPolygonGallery: (constr_id: string, galleryData: postGallery) =>
    axiosInstance.post(
      `constructions/${constr_id}/drawings/photo-contrasts/`,
      galleryData
    ),
  deletePolygonPhoto: (data: { image_id: string[] }, constr_id: string | null) =>
    axiosInstance.delete(`constructions/${constr_id}/drawings/galleries/photo/`, {
      data,
    }),
};

export const DrawingsApi = {
  getDrawingsInfo: (constr_id: string, page = 1, category: string, stext: string) =>
    axiosInstance.get(`/constructions/${constr_id}/drawings/`, {
      params: { page, category, stext },
    }),
  getDrawingsImage: (constr_id: string, drawing_id: string | null) =>
    axiosInstance.get(`/constructions/${constr_id}/drawings/${drawing_id}`),
  postShopDrawing: (constr_id: string, drawing_id: string | null, formData: FormData) =>
    axiosInstance.post(
      `/constructions/${constr_id}/drawings/${drawing_id}/shopdrawing/`,
      formData
    ),
  getShopDrawingDetail: (constr_id: string, shop_id: string | null) =>
    axiosInstance.get(`/constructions/${constr_id}/drawings/shopdrawing/${shop_id}/`),
  deleteShopDrawing: (constr_id: string, shop_id: string | null) =>
    axiosInstance.delete(`/constructions/${constr_id}/drawings/shopdrawing/${shop_id}/`),
  postBookMark: (constr_id: string, drawing_id: string, shop_id: string) =>
    axiosInstance.post(`/constructions/${constr_id}/bookmarks/`, { drawing_id, shop_id }),
  getBookMark: ({ page }: { page: number }, constr_id: string) =>
    axiosInstance.get(`/constructions/${constr_id}/bookmarks?page=${page}`),
  deleteBookMark: (constr_id: string, bookmark_id: string | null) =>
    axiosInstance.delete(`/constructions/${constr_id}/bookmarks/${bookmark_id}/`),
  getDrawingComment: (constr_id: string, drawing_id: string) =>
    axiosInstance.get(`/constructions/${constr_id}/drawings/${drawing_id}/comments/`),
  postDrawingComment: (constr_id: string, drawing_id: string, formData: any) =>
    axiosInstance.post(`/constructions/${constr_id}/drawings/${drawing_id}/comments/`, formData),
  deleteDrawingComment: (constr_id: string, drawing_id: string, comment_id: string) =>
    axiosInstance.delete(`/constructions/${constr_id}/drawings/${drawing_id}/comments/${comment_id}/`),
  putDrawingComment: (constr_id: string, drawing_id: string, comment_id: string, formData: any) =>
    axiosInstance.put(`/constructions/${constr_id}/drawings/${drawing_id}/comments/${comment_id}/`, formData),
};

export const NotificationApi = {
  getNotify: (constr_id: string) =>
    axiosInstance.get(`/constructions/${constr_id}/alarms/`),
  postNotify: (constr_id: string) =>
    axiosInstance.post(`/constructions/${constr_id}/alarms/`),
};
